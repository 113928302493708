export const Chevron = ({ down = true }) => {
  if (down) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ maxWidth: 20 }}
        version="1.1"
        viewBox="0 0 172 172"
      >
        <g
          fill="none"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeDasharray=""
          strokeDashoffset="0"
          fontFamily="none"
          fontWeight="none"
          fontSize="none"
          textAnchor="none"
        >
          <path d="M0,172v-172h172v172z" fill="none" />
          <g fill="#FFF">
            <path d="M17.2,68.8v-11.46667c0,-2.21307 1.27853,-4.2312 3.27947,-5.18293c2.00093,-0.95173 4.3688,-0.65933 6.0888,0.74533l59.43173,48.63013l59.43747,-48.63013c1.71427,-1.40467 4.08213,-1.69133 6.0888,-0.74533c2.00667,0.946 3.27373,2.96987 3.27373,5.18293v11.46667c0,1.72 -0.774,3.34827 -2.10413,4.4376l-63.06667,51.6c-2.1156,1.72573 -5.14853,1.72573 -7.26413,0l-63.06667,-51.6c-1.3244,-1.08933 -2.0984,-2.7176 -2.0984,-4.4376z" />
          </g>
        </g>
      </svg>
    );
  }
};
export const Download = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 172 172"
    style={{ maxWidth: 30 }}
  >
    <g
      fill="none"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="10"
      strokeDasharray=""
      strokeDashoffset="0"
      fontFamily="none"
      fontWeight="none"
      fontSize="none"
      textAnchor="none"
    >
      <path d="M0,172v-172h172v172z" fill="none" />
      <g fill="#FFF">
        <path d="M85.6775,3.3325c-0.14781,0.02688 -0.29562,0.06719 -0.43,0.1075c-1.59906,0.36281 -2.72781,1.80063 -2.6875,3.44v105.135l-21.6075,-21.6075c-0.83312,-0.86 -2.05594,-1.22281 -3.225,-0.9675c-1.29,0.22844 -2.32469,1.16906 -2.6875,2.41875c-0.36281,1.26313 0.01344,2.60688 0.9675,3.49375l27.52,27.52l2.4725,2.365l2.4725,-2.365l27.52,-27.52c1.37063,-1.37062 1.37063,-3.57437 0,-4.945c-1.37062,-1.37062 -3.57437,-1.37062 -4.945,0l-21.6075,21.6075v-105.135c0.04031,-0.99437 -0.36281,-1.94844 -1.075,-2.62031c-0.72562,-0.68531 -1.70656,-1.02125 -2.6875,-0.92719zM24.08,55.04v116.96h123.84v-116.96h-34.4c-1.23625,-0.01344 -2.39187,0.63156 -3.02344,1.70656c-0.61813,1.075 -0.61813,2.39187 0,3.46687c0.63156,1.075 1.78719,1.72 3.02344,1.70656h27.52v103.2h-110.08v-103.2h27.52c1.23625,0.01344 2.39188,-0.63156 3.02344,-1.70656c0.61813,-1.075 0.61813,-2.39187 0,-3.46687c-0.63156,-1.075 -1.78719,-1.72 -3.02344,-1.70656z" />
      </g>
    </g>
  </svg>
);

export const Logout = () => (
  <svg version="1.1" viewBox="0 0 172 172" style={{ maxWidth: 27 }}>
    <g
      fill="none"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="10"
      strokeDasharray=""
      strokeDashoffset="0"
      fontFamily="none"
      fontWeight="none"
      fontSize="none"
      textAnchor="none"
    >
      <path d="M0,172v-172h172v172z" fill="none" />
      <g fill="#FFF">
        <path d="M86,2.6875l-28.66667,40.3125h21.5v64.5h14.33333v-64.5h21.5zM43,11.70182c-25.628,14.93533 -43,42.65018 -43,74.29818c0,47.3 38.7,86 86,86c47.3,0 86,-38.7 86,-86c0,-31.648 -17.372,-59.36284 -43,-74.29818v17.16081c17.3505,13.10783 28.66667,33.8457 28.66667,57.13737c0,39.41667 -32.25,71.66667 -71.66667,71.66667c-39.41667,0 -71.66667,-32.25 -71.66667,-71.66667c0,-23.29883 11.31617,-44.02954 28.66667,-57.13737z" />
      </g>
    </g>
  </svg>
);

export const AddIcon = () => (
  <svg width="25px" height="23px" viewBox="0 0 25 23" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD-Copy-2"
        transform="translate(-24.000000, -786.000000)"
        stroke="#FC5542"
      >
        <g id="Group-4" transform="translate(25.000000, 786.982422)">
          <g id="Group-3">
            <polyline id="Path-2" points="5 0 0 0 0 21 5 21"></polyline>
            <polyline
              id="Path-2-Copy"
              transform="translate(20.500000, 10.500000) scale(-1, 1) translate(-20.500000, -10.500000) "
              points="23 0 18 0 18 21 23 21"
            ></polyline>
          </g>
          <line
            x1="5.5"
            y1="10.5175781"
            x2="17.5"
            y2="10.5175781"
            id="Line"
            strokeLinecap="square"
          ></line>
          <line
            x1="5.5"
            y1="10.5175781"
            x2="17.5"
            y2="10.5175781"
            id="Line"
            strokeLinecap="square"
            transform="translate(11.500000, 10.517578) rotate(90.000000) translate(-11.500000, -10.517578) "
          ></line>
        </g>
      </g>
    </g>
  </svg>
);
