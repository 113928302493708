import { useState, useEffect } from "react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import awsconfig from "../../aws-exports";
import { listRecords } from "../../graphql/queries";
import { createRecord, deleteRecord } from "../../graphql/mutations";
import styles from "../../assets/styles/Home.module.scss";

import Navbar from "../../components/Nav";
import Header from "../../components/Header";
import Subnav from "../../components/Subnav";
import Form from "../../components/Form";
import Shopping from "../../components/Shopping";

Amplify.configure(awsconfig);

function Home() {
  const [records, setRecords] = useState([]);
  const [form, setForm] = useState({
    bodyPart: "",
    typeOfDisconfort: "",
    level: "",
    how: "",
    startDate: "",
    medication: "",
  });
  const [nav, setNav] = useState(false);
  const [addForm, setaddForm] = useState(false);
  const [shopping, setShopping] = useState(false);

  const save = async (e) => {
    e.preventDefault();
    try {
      await API.graphql(
        graphqlOperation(createRecord, {
          input: form,
        })
      );
      fetch();
      setNav(false);
      setaddForm(true);
      setShopping(false);
    } catch (e) {
      console.log(`There was an error saving the record: ${JSON.stringify(e)}`);
    }
  };

  const deleteItem = async (id) => {
    try {
      await API.graphql(
        graphqlOperation(deleteRecord, {
          input: { id },
        })
      );
      fetch();
    } catch (error) {
      if (error) {
        return console.log(
          `There was an error deleting the record: ${JSON.stringify(error)}`
        );
      }
    }
  };

  const fetch = async () => {
    try {
      const report = await API.graphql(graphqlOperation(listRecords));
      const result = report.data.listRecords.items;
      setRecords(result);
    } catch (e) {
      if (e) {
        console.log(
          `There was an error fetching the records: ${JSON.stringify(e)}`
        );
      }
    }
  };

  useEffect(() => {
    fetch();
    const mainLogo = document.getElementById("main-logo");
    mainLogo.remove();
  }, []);

  return (
    <div className="App">
      <header className="App-header">{/* <AmplifySignOut /> */}</header>

      <Navbar
        setNav={setNav}
        setShopping={setShopping}
        setaddForm={setaddForm}
      />
      {addForm && (
        <Subnav
          records={records}
          deleteItem={deleteItem}
          setShopping={setShopping}
        />
      )}
      {shopping && (
        <Shopping
          records={records}
          deleteItem={deleteItem}
          setShopping={setShopping}
        />
      )}
      <div className={`container ${styles.home__section}`}>
        <div>
          <Header />
          <img
            onClick={(e) => {
              e.preventDefault();
              setNav(true);
              setShopping(false);
              setaddForm(false);
            }}
            className={styles.human}
            src="https://impactrepublic.github.io/helium/images/human.png"
            with={448.7}
            alt="Human"
          />
        </div>
        {nav && (
          <Form
            form={form}
            setForm={setForm}
            save={save}
            setNav={setNav}
            setShopping={setShopping}
          />
        )}
      </div>
    </div>
  );
}

export default withAuthenticator(Home);
