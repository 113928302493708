import React from "react";
import Input from "../Input";
import Styles from "./Form.module.scss";
import { AddIcon } from "../Icons";

const Form = ({ save, form, setForm, setNav, setShopping }) => {
  return (
    <form className={Styles.form} action="#0" onSubmit={(e) => save(e)}>
      <h1>{AddIcon()} Add Record</h1>
      <p>
        Tap the area of your avatar to notate physical discomfort, pain,
        soreness, stiffness, swelling, aches, weakness, numbness, or mental and
        emotional states.{" "}
      </p>
      <div>
        <Input
          setForm={setForm}
          form={form}
          type="text"
          placeholder="i.e Right arm thumb"
          name="bodyPart"
          label="Specify body part"
        />
        <Input
          setForm={setForm}
          form={form}
          type="text"
          placeholder="Pain, sureness, Numbness"
          name="typeOfDisconfort"
          label="Type of discomfort"
        />
        <Input
          setForm={setForm}
          form={form}
          type="number"
          max={10}
          min={1}
          placeholder="1-10"
          name="level"
          label="Level of discomfort 1 - 2"
        />
        <Input
          setForm={setForm}
          form={form}
          type="text"
          placeholder="i.e. An accident"
          name="how"
          label="How did the issue started?"
        />
        <Input
          setForm={setForm}
          form={form}
          type="date"
          placeholder="i.e. An accident"
          name="startDate"
          label="When the  issue started?"
        />
        <Input
          setForm={setForm}
          form={form}
          type="text"
          placeholder="i.e. Motrin"
          name="medication"
          label="Medication?"
        />
        <button className="btn btn-primary" type="submit">
          Add Record
        </button>
        <button
          className="btn btn-secondary"
          type="button"
          onClick={() => {
            setNav(false);
            setShopping(false);
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default Form;
