import React from "react";

const Header = () => (
  <>
    <img
      className="logo"
      src="https://impactrepublic.github.io/helium/images/logo.png"
      with={165}
      height={165}
      alt="Helium-logo"
    />
    <p>
      Welcome to your personal journal to track how you feel day-to-day in your
      physical, mental, and emotional health journey. Keep track and share with
      trainers, physicians, therapists, psychologists, coaches, or
      accountability partners.
    </p>
  </>
);

export default Header;
