import styles from "./About.module.css";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <Link to="/" className={`btn-primary ${styles.link}`}>
          Home
        </Link>
        <Link to="/about" className={`btn-primary ${styles.link}`}>
          About
        </Link>
        <Link to="/contact-us" className={`btn-primary ${styles.link}`}>
          Contact Us
        </Link>
        <Link to="/home" className={`btn-primary ${styles.button}`}>
          Login/Signup
        </Link>
      </div>
      <div> </div>
      <div className={styles.modal}>
        <img
          id="main-logo"
          className="logo"
          src="https://impactrepublic.github.io/helium/images/logo.png"
          with={150}
          height={150}
          style={{ marginBottom: 50 }}
          alt="Helium-logo"
        />
        <h4 className={styles.title}>Who Are We</h4>
        <p>
          We're a passionate team dedicated to health and fitness who are
          building products that help transform people's lives. While health can
          be serious business, we feel it doesn't have to be. We believe you're
          more likely to reach your goals if you're encouraged to have fun,
          smile, and feel empowered along the way.
        </p>

        <h4 className={styles.title}>Mission</h4>
        <p>
          To empower and inspire you to live a healthier, more active life. We
          design products and experiences that fit seamlessly into your life so
          you can achieve your health and fitness goals, whatever they may be.
        </p>
      </div>
      <footer className={styles.footer}>
        386 Park Avenue South, 18th Floor NY, NY 10016{" "}
        <strong>P. 212-441-0000</strong>
        <a href="mailto:hello@humanelevation.com">hello@humanelevation.com</a>
      </footer>
    </div>
  );
};

export default About;
