import React from "react";
import styles from "./Shopping.module.scss";

const addIcon = () => (
  <svg width="25px" height="23px" viewBox="0 0 25 23" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD-Copy-2"
        transform="translate(-24.000000, -786.000000)"
        stroke="#FC5542"
      >
        <g id="Group-4" transform="translate(25.000000, 786.982422)">
          <g id="Group-3">
            <polyline id="Path-2" points="5 0 0 0 0 21 5 21"></polyline>
            <polyline
              id="Path-2-Copy"
              transform="translate(20.500000, 10.500000) scale(-1, 1) translate(-20.500000, -10.500000) "
              points="23 0 18 0 18 21 23 21"
            ></polyline>
          </g>
          <line
            x1="5.5"
            y1="10.5175781"
            x2="17.5"
            y2="10.5175781"
            id="Line"
            strokeLinecap="square"
          ></line>
          <line
            x1="5.5"
            y1="10.5175781"
            x2="17.5"
            y2="10.5175781"
            id="Line"
            strokeLinecap="square"
            transform="translate(11.500000, 10.517578) rotate(90.000000) translate(-11.500000, -10.517578) "
          ></line>
        </g>
      </g>
    </g>
  </svg>
);

const Shopping = ({ records, deleteItem, setShopping }) => {
  return (
    <div className={styles.subnav}>
      <h1>{addIcon()} Studies</h1>
      <a
        href="#0"
        className={styles.closeBtn}
        onClick={(e) => {
          e.preventDefault();
          setShopping(false);
        }}
      >
        X
      </a>
      <p>
        When you need the information to help a physician, professional, or
        expert understand a problem or condition you are facing, we aid in you
        providing them a quality report.
      </p>
      <div className="container">
        <div className="row">
          <div className={`col-md-4 ${styles.thumb}`}>
            <img src="images/thumb-2.jpg" alt="thumb" />
            <h5>What seems to be the problem?</h5>
            <p>
              We provide a readable report with charts, graphs, summaries, and
              insights of your body observations based on your pain rating.
            </p>
          </div>
          <div className={`col-md-4 ${styles.thumb}`}>
            <img src="images/thumb-3.jpg" alt="thumb" />
            <h5>What have I been taking? </h5>
            <p>
              We provide a report of the medications you have taken over time
              and for what reasons.
            </p>
          </div>
          <div className={`col-md-4 ${styles.thumb}`}>
            <img src="images/thumb-1.jpg" alt="thumb" />
            <h5>How have I been? </h5>
            <p>
              We provide a visual report of your records to review with experts,
              professionals, and accountability partners.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="container" style={{ textAlign: "center" }}>
            <a
              className={`btn btn-primary ${styles.contact__us__btb}`}
              href="mailto:hello@humanelevation.com"
            >
              Buy now
            </a>
            <p style={{ maxWidth: "100%", marginLeft: 0, marginTop: "10px" }}>
              To order a report please, contact us
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shopping;
