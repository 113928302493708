/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRecord = /* GraphQL */ `
  query GetRecord($id: ID!) {
    getRecord(id: $id) {
      id
      bodyPart
      typeOfDisconfort
      level
      how
      startDate
      medication
      createdAt
      updatedAt
    }
  }
`;
export const listRecords = /* GraphQL */ `
  query ListRecords(
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bodyPart
        typeOfDisconfort
        level
        how
        startDate
        medication
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
