import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./views/Home/";
import About from "./views/About/";
import ContactUs from "./views/ContactUs/";
import Splash from "./views/Splash/";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Splash />
        </Route>
        <Route exact path="/About">
          <About />
        </Route>
        <Route exact path="/contact-us">
          <ContactUs />
        </Route>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route path="*">
          <h2>404 Page not found</h2>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
