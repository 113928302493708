import styles from "./Splash.module.css";
import { Link } from "react-router-dom";

const Splash = () => {
  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <Link to="/" className={`btn-primary ${styles.link}`}>
          Home
        </Link>
        <Link to="/about" className={`btn-primary ${styles.link}`}>
          About
        </Link>
        <Link to="/contact-us" className={`btn-primary ${styles.link}`}>
          Contact Us
        </Link>
        <Link to="/home" className={`btn-primary ${styles.button}`}>
          Login/Signup
        </Link>
      </div>
      <div> </div>
      <div className={styles.modal}>
        <img
          id="main-logo"
          className="logo"
          src="https://impactrepublic.github.io/helium/images/logo.png"
          with={200}
          height={200}
          style={{ margin: "0 auto 2em", display: "block" }}
          alt="Helium-logo"
        />
        <h4 className={styles.title}>
          TRACK, MEASURE, COMMUNICATE AND ANALYSIS YOUR PERSONAL HEALTH JOURNEY{" "}
        </h4>
        <p>
          The Helium app for desktop allows you to track important health data
          such as daily status, medication taken, and bodies response to
          activity, and more for you to be able to communicate and measure how
          you are doing to health experts and professionals.
        </p>
        <a
          href="https://he-general-purpose.s3.amazonaws.com/Helium-x64.zip"
          className={`btn-primary ${styles.button}`}
        >
          Download App
        </a>
      </div>
      <footer className={styles.footer}>
        386 Park Avenue South, 18th Floor NY, NY 10016{" "}
        <strong>P. 212-441-0000</strong>
        <a href="mailto:hello@humanelevation.com">hello@humanelevation.com</a>
      </footer>
    </div>
  );
};

export default Splash;
