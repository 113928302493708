import React from "react";
import styles from "./Input.module.scss";

const Input = ({
  setForm,
  form,
  name,
  type,
  placeholder,
  label,
  max = 10,
  min = 1,
}) => (
  <div className={styles.form__group}>
    <label htmlFor={name}>{label}</label>
    <input
      className={styles.input}
      name={name}
      type={type}
      placeholder={placeholder}
      onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
    />
  </div>
);

export default Input;
