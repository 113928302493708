/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:3067393f-e0a2-41fa-880d-87e42a33503a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_neVf6MKqB",
    "aws_user_pools_web_client_id": "f90ha1q91spsqq1r2t0pcq1pv",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://qasaetipe5ecxmwbmlnaj3g7sq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
