import React from "react";
import styles from "./Navbar.module.scss";
import { Logout } from "../Icons";
import { Auth } from "aws-amplify";
import { Download } from "../Icons";

const Navbar = ({ setNav, setShopping, setaddForm }) => {
  const signOut = async (e) => {
    e.preventDefault();
    try {
      await Auth.signOut();
      document.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  return (
    <div className={styles.nav}>
      <div className="links-wrapper">
        <a
          className={styles.item}
          href="#0"
          onClick={(e) => {
            e.preventDefault();
            setNav(true);
            setShopping(false);
            setaddForm(false);
          }}
          title="Add record"
        >
          <img
            src="https://impactrepublic.github.io/helium/images/add-icon.svg"
            alt="add"
          />
        </a>
        <a
          className={styles.item}
          href="#0"
          onClick={(e) => {
            e.preventDefault();
            setNav(false);
            setShopping(false);
            setaddForm(true);
          }}
        >
          <img
            src="https://impactrepublic.github.io/helium/images/reports-icon.svg"
            alt="reports"
          />
        </a>
        <a className={styles.item} href="#0" title="Buy report">
          <img
            src="https://impactrepublic.github.io/helium/images/shopping-icon.svg"
            alt="reports"
            onClick={(e) => {
              e.preventDefault();
              setNav(false);
              setaddForm(false);
              setShopping(true);
            }}
          />
        </a>
        <a
          className={styles.item}
          href="https://impactrepublic.github.io/helium/download/Helium-x64.zip"
          download
          alt="Download"
        >
          <Download />
        </a>
        <a className={styles.item} href="#0" onClick={(e) => signOut(e)}>
          <Logout />
        </a>
        {/* <a className={styles.item} href="#0">
          <img
            src="https://impactrepublic.github.io/helium/images/share-icon.svg"
            alt="reports"
          />
        </a> */}
      </div>
    </div>
  );
};

export default Navbar;
