import React, { useState } from "react";
import styles from "./Subnav.module.scss";

const Details = ({ record, close }) => {
  console.log(record);
  return (
    <div className={styles.records}>
      <a
        href="#0"
        onClick={(e) => {
          e.preventDefault();
          close(false);
        }}
      >
        X
      </a>
      <div className="record__details">
        <h5>Body part</h5>
        <p>{record.bodyPart}</p>
        <hr />

        <h5>How it started</h5>
        <p>{record.how}</p>
        <hr />

        <h5>Type of disconfort</h5>
        <p>{record.typeOfDisconfort}</p>
        <hr />

        <h5>Level of disconfort</h5>
        <p>{record.level}</p>
        <hr />

        <h5>When did it started</h5>
        <p>{record.startDate}</p>
        <hr />

        <h5>Medication?</h5>
        <p>{record.medication}</p>
        <hr />
      </div>
    </div>
  );
};
const Subnav = ({ records, deleteItem }) => {
  const [detail, setDetail] = useState(false);
  const [record, setRecord] = useState({});
  return (
    <div className={styles.subnav}>
      {detail && <Details record={record} close={setDetail} />}
      <h1>Records</h1>
      <p>
        Tap the area of your avatar to notate physical discomfort, pain,
        soreness, stiffness, swelling, aches, weakness, numbness, or mental and
        emotional states.{" "}
      </p>

      <ul>
        {records.map((item, idx) => {
          const date = new Date(item.startDate);
          return (
            <li
              key={idx}
              onClick={(e) => {
                e.stopPropagation();
                setDetail(true);
                setRecord(item);
              }}
            >
              <h5>{item.bodyPart}</h5>
              <p>
                {`${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`}{" "}
                <a
                  href="#0"
                  onClickCapture={(e) => {
                    e.preventDefault();
                    deleteItem(item.id);
                  }}
                >
                  X
                </a>
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Subnav;
